// Override default variables before the import
$enable-rounded: false;
$body-color:#1c1a17;
$green: #b9e1c4;

$input-border-color: $green;

$link-color: $body-color;
// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";
@import url("https://fonts.googleapis.com/css?family=Inder&display=swap");

@import "./styles/mixins";
@import "./styles/globals";
@import "./styles/navbar";
@import "./styles/footer";
@import "./styles/home-page";
@import "./styles/portfolio";