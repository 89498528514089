.portfolio-container {
    .portfolio-item {
        position: relative;
        width: 100%;
        height: auto;
        margin-bottom: $grid-gutter-width/2;

        .media-object {
            width: 100%;
            height: auto;
            display: block;
        }

        .overlay {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            top: 20px;
            left: 20px;
            right: 20px;
            bottom: 20px;
            padding: 20px;
            background-color: rgba($color: $success, $alpha: 0.8);
            opacity: 0;
            cursor: pointer;
            @include transition(opacity 0.3s 0.1s);

            .caption {
                text-transform: uppercase;
                text-align: center;

                h4 {
                    font-size: 18px;
                    font-weight: 500;
                }

                small {
                    display: block;
                    font-size: 0.6rem;
                    margin-top: 10px;

                    &:before {
                        content: 'x';
                        margin: 0.5rem 0;
                        display: block;
                    }
                }

            }
        }

        &:hover {
            .overlay {
                opacity: 1;
            }
        }
    }
}

.project-container {
    .project-images {
        .project-img {
            display: block;
            margin-bottom: $grid-gutter-width/2;
            cursor: pointer;
            opacity: 1;
            @include transition(opacity 0.3s 0.1s);

            &:hover {
                opacity: 0.8;
            }
        }
    }

    .project-capture {
        padding-top: $grid-gutter-width;

        h4 {
            margin-bottom: 2rem;
        }

        .project-desc {
            margin-bottom: $grid-gutter-width/2;
        }
    }
}