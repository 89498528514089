.images-slider {

    .slide {
        opacity: 0;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;

        @include transition(opacity 0.6s);

        &.current {
            opacity: 1;
        }
    }
}

.home-page-text {
    h2 {
        font-family: 'Inder', Arial, sans-serif;
        font-weight: 500;
        font-size: 1.2rem;
        text-transform: none;
        line-height: 1.8em;
        color: darken($success, 45%);
        margin-top: $grid-gutter-width/2;
        margin-bottom: $grid-gutter-width/2;
    }

    h4 {
        font-family: 'Inder', Arial, sans-serif;
        font-weight: 100;
        font-size: 1rem;
        line-height: 2em;
        margin-bottom: $grid-gutter-width;
    }
}

hr {
    border-color: $gray-600;
    border-width: 1px;
}