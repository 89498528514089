.footer {
    background-color: $white;
    font-size: 12px;
    padding-top: $grid-gutter-width/2;
    padding-bottom: $grid-gutter-width/2;

    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;

    a {
        padding: 0.2rem;

        &:hover {
            color: $warning;
        }
    }
}