.navbar {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    background-color: $white;


    @include media-breakpoint-up(lg) {
        padding-top: $grid-gutter-width;
        padding-bottom: $grid-gutter-width;
        box-shadow: none;
    }



    @include transition(padding 0.3s 0s);


    &.scrolled {
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        padding-top: $navbar-padding-y;
        padding-bottom: $navbar-padding-y;
    }

    .navbar-toggler {
        color: $body-color;
    }

    .navbar-brand {
        &.logo-text {
            font-family: "Inder", Arial, sans-serif;
            font-weight: 700;
            font-size: 30px;
            text-transform: uppercase;
            letter-spacing: 1px;
            line-height: 1;
        }
    }

    .navbar-collapse {
        justify-content: flex-end;

        .navbar-nav {
            .nav-item {
                .nav-link {
                    font-size: 15px;
                    font-weight: 500;
                    padding-left: 0;
                    padding-right: 0;
                    padding-bottom: 0.3rem;

                    border-bottom: 3px solid $gray-200;

                    @include transition(all 0.3s 0s);

                    @include media-breakpoint-up(lg) {
                        border-bottom: 3px solid transparent;
                    }

                    &.active {
                        border-color: $success;
                    }
                }

                &.language-btns {
                    direction: ltr;
                    padding-top: 0.5rem;
                    padding-bottom: 0.2rem;

                    .btn-sm {
                        font-size: 0.7rem;
                        display: inline-block;
                        margin: 0.1rem;
                    }
                }
            }
        }
    }
}

.rtl {
    .navbar {
        .navbar-toggler {
            padding-left: 0;
        }

        .navbar-brand {
            margin-right: 0;
            margin-left: 1rem;
        }

        .navbar-nav {
            .nav-item {
                padding-right: 3rem;
                padding-left: 0;
            }
        }
    }


}

.ltr {
    .navbar {
        .navbar-toggler {
            padding-right: 0;
        }

        .navbar-brand {
            margin-right: 1rem;
            margin-left: 0;
        }

        .navbar-nav {
            .nav-item {
                padding-right: 0;
                padding-left: 3rem;
            }
        }
    }
}